import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {OverlayPanel} from "primereact/overlaypanel";
import {FormEvent} from "primereact/ts-helpers";
import {UserRole} from "../../../models/enums/user-role.enum";
import {Employee} from "../../../models/employee.interface";
import {Shift} from "../../../models/shift.interface";
import {HiOutlineUserCircle} from "react-icons/hi";
import {
    retrieveAvailableWorkersBetweenDates,
    retrieveShiftsWeekByDate,
    retrieveWorkersByDate
} from "../../../services/data-manager/data-manager.service";
import {WorkersByDateRequest} from "../../../services/backend/api/shifts/requests/workers-by-date.request";
import {
    AvailableWorkersBetweenDatesRequest
} from "../../../services/backend/api/shifts/requests/available-workers-between-dates.request";
import {updateCalendar} from "../../../services/backend/api/shifts/shifts.apis";
import {UpdateCalendarRequest} from "../../../services/backend/api/shifts/requests/update-calendar.request";
import {ShiftItem} from "../../../services/backend/api/shifts/models/shift-item.interface";
import {formatDate, today} from "../../../utils/date.utils";
import {useToastContext} from "../../../contexts/ToastContext";
import FullCalendar from '@fullcalendar/react'
import interactionPlugin from '@fullcalendar/interaction'
import {Calendar} from "primereact/calendar";
import {InputText} from "primereact/inputtext";
import {MultiSelect} from "primereact/multiselect";
import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";
import {FaRegEdit} from "react-icons/fa";
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import listPlugin from '@fullcalendar/list';
import {simplifiedId} from "../../../utils/text-formatting.utils";

const FULLCALENDAR_API_KEY = process.env.REACT_APP_FULLCALENDAR_LICENSE_KEY

interface WeeklyCalendarProps {
    onChange: (date: Date) => void;
    onVisibleShiftsChange: (shifts: Shift[]) => void;
}

const WEEK_DAYS = 7;
const START_HOUR = 0;
const END_HOUR = 23;
const HOURS = END_HOUR - START_HOUR + 1;
const DEFAULT_SHIFT_HOURS_DURATION = 8;
const STEP_MINUTE = 15;
const NEW_SHIFT = 'New Shift';

const WeeklyCalendar: React.FC<WeeklyCalendarProps> = ({onChange, onVisibleShiftsChange}) => {
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const {successToast, errorToast} = useToastContext();

    const [firstWeekDayDate, setFirstWeekDayDate] = useState<Date>(today());
    const [lastWeekDayDate, setLastWeekDayDate] = useState<Date>(today());
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const [startTime, setStartTime] = useState<Date>(new Date());
    const [endTime, setEndTime] = useState<Date>(new Date());

    const [selectedLocation, setSelectedLocation] = useState<string>();
    const [selectedSupervisor, setSelectedSupervisor] = useState<Employee>();
    const [selectedOperators, setSelectedOperators] = useState<Employee[]>();
    const [availableSupervisors, setAvailableSupervisors] = useState<Employee[]>([]);
    const [availableOperators, setAvailableOperators] = useState<Employee[]>([]);

    const [availableShifts, setAvailableShifts] = useState<Shift[]>([]);
    const [visibleShifts, setVisibleShifts] = useState<Shift[]>([]);

    const [selectedShift, setSelectedShift] = useState<Shift>();
    const [shiftHoursDuration, setShiftHoursDuration] = useState<number>(DEFAULT_SHIFT_HOURS_DURATION);

    const [shiftOperatorsVisible, setShiftOperatorsVisible] = useState<Employee[]>([]);

    const assignShiftOverlayPanel = useRef<OverlayPanel>(null)
    const shiftOperatorsOverlayPanel = useRef<OverlayPanel>(null)

    const handleShiftSelection = (shift: Shift) => {
        setSelectedShift(shift);
        setStartTime(shift.startDate);
        setEndTime(shift.endDate);
        setSelectedSupervisor(shift.supervisor);
        setSelectedOperators(shift.operators);
        setSelectedLocation(shift.place);
    }

    const handleNewShiftForm = () => {
        setSelectedShift(undefined);
        setSelectedSupervisor(undefined);
        setSelectedOperators([]);
        setSelectedLocation(undefined);
    }

    const showAssignShiftOverlayPanel = (e: any, shiftId?: string) => {
        if (shiftId) {
            const shift = availableShifts.find(shift => shift.id === shiftId);
            if (shift) {
                handleShiftSelection(shift);
            }
        } else {
            handleNewShiftForm();
        }
        assignShiftOverlayPanel.current?.toggle(e, e.target);
    };

    const onHideAssignShiftOverlayPanel = () => {
        setStartTime(new Date(firstWeekDayDate));
        setEndTime(new Date(firstWeekDayDate));
    }

    const onlyHourDate = (date: Date): Date => {
        const newDate = new Date(date);
        newDate.setMinutes(0);
        newDate.setSeconds(0);
        return newDate;
    }

    const setStartHour = (e: FormEvent<(Date | null)>) => {
        const date = new Date(e.target.value || firstWeekDayDate);
        if (date.getHours() < START_HOUR) {
            return;
        }
        setStartTime(date);
    };

    const setEndHour = (e: FormEvent<(Date | null)>) => {
        let date = new Date(e.target.value || startTime);
        if (date < startTime) {
            date = new Date(startTime);
        }
        if (date.getHours() > END_HOUR + 1) {
            return;
        }
        setEndTime(date);
    };

    const handleSupervisorSelection = (e: any) => {
        setSelectedSupervisor(e.value);
    };

    const saveShift = () => {
        if (!startTime || !endTime || !selectedSupervisor || !selectedOperators || startTime >= endTime) {
            return;
        }
        let isUpdate = false;
        let shift: Shift;
        if (selectedShift !== undefined) {
            isUpdate = true;
            shift = selectedShift;
        } else {
            shift = {id: ""} as Shift;
        }
        shift.startDate = startTime;
        shift.endDate = endTime;
        shift.supervisor = selectedSupervisor;
        shift.operators = selectedOperators;

        for (let i = 0; i < shift.endDate.getHours() - shift.startDate.getHours(); i++) {
            if (isSlotAssigned(shift.startDate.getHours() + i, shift.startDate.getDay())) {
                assignShiftOverlayPanel.current?.hide();
                return;
            }
        }
        const shiftsItems: ShiftItem[] = []
        const employees = selectedOperators.concat(selectedSupervisor);
        for (let i = 0; i < employees.length; i++) {
            const employee = employees[i];
            const shiftItem: ShiftItem = {
                shiftId: isUpdate ? shift.id : undefined,
                proposal: {},
                endDate: formatDate(shift.endDate) || '',
                label: 'Work',
                proposalStatus: 'false',
                userStart: '',
                startTime: shift.startDate.toISOString(),
                endTime: shift.endDate.toISOString(),
                userEnd: '',
                userId: employee.id,
                startDate: formatDate(shift.startDate) || '',
                place: selectedLocation || '',
            }
            shiftsItems.push(shiftItem);
        }

        const request: UpdateCalendarRequest = {
            shiftsAdd: !isUpdate ? shiftsItems : [],
            shiftsUpdate: isUpdate ? shiftsItems : [],
            shiftsDelete: []
        }
        updateCalendar(request).then((response) => {
            setSelectedShift(undefined);
            assignShiftOverlayPanel.current?.hide();
            retrieveShiftsWeekByDate({date: selectedDate}).then(shifts => {
                setAvailableShifts(shifts);
                if (response.notAdded.length > 0 || response.notUpdated.length > 0) {
                    errorToast(t('messages.calendarUpdatedError'));
                } else {
                    successToast(t('messages.calendarUpdatedSuccess'));
                }
            })
        }).catch(() => {
            errorToast(t('messages.calendarUpdatedError'));
        });
    }

    const getAssignedShift = (hour: number, day: number): Shift | undefined => {
        const actualDate = new Date(firstWeekDayDate);
        actualDate.setDate(actualDate.getDate() + day);
        actualDate.setHours(START_HOUR + hour);

        return availableShifts.find(shift => {
            return actualDate >= shift.startDate && actualDate < shift.endDate
        });
    }

    const getShiftById = (id: string): Shift | undefined => {
        return availableShifts.find(shift => shift.id === id);
    }

    const isSlotAssigned = (hour: number, day: number) => {
        return getAssignedShift(hour, day) !== undefined;
    }

    const isShiftSelected = (hour: number, day: number) => {
        const shift = getAssignedShift(hour, day);
        return selectedShift && selectedShift.startDate === shift?.startDate && selectedShift.endDate === shift?.endDate;
    }

    const isEditing = (hour: number, day: number): boolean => {
        if (!startTime || !endTime || (startTime === endTime) || startTime >= endTime) {
            return false;
        }
        if (startTime.getDay() !== day && endTime.getDay() !== day) {
            return false
        }
        return startTime.getHours() <= hour && endTime.getHours() > hour;
    }


    const selectedEmployeeItem = (option: Employee) => {
        return (
            <div className="flex items-center justify-center"
                 style={{
                     backgroundColor: option?.isOnLeave ? 'green' : 'var(--primary-color)',
                     opacity: option?.isOnLeave || option?.assigned ? 0.5 : 1
                 }}
            >
                <div>{option?.name} {option?.surname}</div>
            </div>
        );
    };

    const showShiftOperators = (e: any, operators: Employee[]) => {
        setShiftOperatorsVisible(operators)
        shiftOperatorsOverlayPanel.current?.toggle(e)
        e.stopPropagation()
    }

    const hideShiftOperators = () => {
        setShiftOperatorsVisible([])
        shiftOperatorsOverlayPanel.current?.hide()
    }

    const getDayResources = (): any[] => {
        const resources = availableShifts.map(shift => {
            return {
                id: shift.id,
                title: simplifiedId(shift.id)
            }
        })

        resources.push({
            id: NEW_SHIFT,
            title: NEW_SHIFT
        })

        return resources
    }

    const getCalendarEvent = (): any[] => {
        const calendarShifts = availableShifts.map(shift => {
            return {
                title: shift.id,
                resourceId: shift.id,
                start: shift.startDate,
                end: shift.endDate,
                allDay: false
            }
        });
        if (startTime !== undefined && endTime !== undefined && startTime < endTime) {
            calendarShifts.push({
                title: NEW_SHIFT,
                resourceId: NEW_SHIFT,
                start: startTime,
                end: endTime,
                allDay: false
            })
        }
        return calendarShifts;
    }

    useEffect(() => {
        const request: AvailableWorkersBetweenDatesRequest = {
            startTime: startTime,
            endTime: endTime
        }
        // TODO: remove this line when the backend is ready
        request.endTime.setMinutes(request.endTime.getMinutes() - 1);
        retrieveAvailableWorkersBetweenDates(request).then((employees) => {
            setAvailableSupervisors(employees.filter(employee => employee.type === UserRole.Supervisor));
            setAvailableOperators(employees.filter(employee => employee.type === UserRole.Operator));
        });
    }, [endTime, startTime]);

    useEffect(() => {
        setIsLoading(true);
        const weekday = onlyHourDate(selectedDate);
        setStartTime(weekday);
        setEndTime(weekday);
        const firstWeekDay = weekday.getDate() - weekday.getDay();
        const firstDay = new Date();
        const lastDay = new Date();
        firstDay.setDate(firstWeekDay);
        lastDay.setDate(firstWeekDay + WEEK_DAYS - 1);
        setFirstWeekDayDate(firstDay);
        setLastWeekDayDate(lastDay);

        const request: WorkersByDateRequest = {
            dateDate: selectedDate
        };
        retrieveWorkersByDate(request).then((employees) => {
            console.log(employees);
            setAvailableSupervisors(employees.filter(employee => employee.type === UserRole.Supervisor));
            setAvailableOperators(employees.filter(employee => employee.type === UserRole.Operator));
        });
        retrieveShiftsWeekByDate({date: selectedDate}).then(shifts => {
            setAvailableShifts(shifts);
            setIsLoading(false);
        })
    }, [selectedDate]);

    useEffect(() => {
        setVisibleShifts(availableShifts.filter(
            shift => shift.startDate >= firstWeekDayDate && shift.endDate < lastWeekDayDate)
        );
    }, [firstWeekDayDate, lastWeekDayDate, availableShifts]);

    useEffect(() => {
        onChange(selectedDate);
    }, [onChange, selectedDate]);

    useEffect(() => {
        onVisibleShiftsChange(visibleShifts);
    }, [onVisibleShiftsChange, visibleShifts]);

    const ShiftItem: React.FC<{ shift?: Shift }> = ({shift}) => {
        return (
            <>
                {!shift
                    ? <div></div>
                    :
                    <div className="flex items-center justify-center gap-6 p-4">
                        <div className="flex flex-col items-center justify-center">
                            <p>{t('weeklyCalendar.supervisor')}</p>
                            <HiOutlineUserCircle size={24} className="text-gray-400"/>
                        </div>
                        <div className="min-w-20">
                            <div>{shift.startDate.toLocaleString('default', {hour: 'numeric'})}
                                {" - "}
                                {shift.endDate.toLocaleString('default', {hour: 'numeric'})}
                            </div>
                        </div>
                        <div className="flex flex-col items-center justify-center">
                            <p>{t('weeklyCalendar.operators')}</p>
                            <div className="flex items-center justify-center">
                                <HiOutlineUserCircle size={24} className="text-gray-400"
                                                     onClick={(e) => showShiftOperators(e, shift.operators)}
                                />
                            </div>
                        </div>
                        {
                            <OverlayPanel ref={shiftOperatorsOverlayPanel}
                                          showCloseIcon
                                          onHide={hideShiftOperators}
                                          onClick={e => e.stopPropagation()}
                                          className={`primary-container p-2 border-4 translate-y-[32rem]`}
                            >
                                <div
                                    className="flex flex-col justify-center items-center p-4 space-y-2 w-full text-[var(--text-color)]">
                                    <div className="flex items-center justify-center">
                                        <p className="text-lg font-semibold">{t('weeklyCalendar.operators')}</p>
                                    </div>
                                    {
                                        shiftOperatorsVisible.map((operator, index) => {
                                            return (
                                                <div key={index} className="flex items-center justify-center">
                                                    <div>{operator?.name} {operator?.surname}</div>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </OverlayPanel>
                        }
                    </div>
                }
            </>
        );
    }

    const setShiftDate = (start: Date, end: Date) => {
        setStartTime(start);
        setEndTime(end);
        saveShift();
    }

    const handleDateSelect = (selectInfo: any) => {
        setStartTime(selectInfo.start);
        setEndTime(selectInfo.end);
        showAssignShiftOverlayPanel(selectInfo.jsEvent);
    }

    const handleEventClick = (clickInfo: any) => {
        showAssignShiftOverlayPanel(clickInfo.jsEvent, clickInfo.event.title);
    }

    const handleEvents = (events: any) => {
        console.log('handleEvents');
    }

    const handleEventChange = (event: any) => {
        const shift = getAssignedShift(event.oldEvent.start.getHours(), event.oldEvent.start.getDay());
        if (!shift) {
            return;
        }
        handleShiftSelection(shift);
        setShiftDate(event.event.start, event.event.end);
    }

    const renderEventContent = (eventInfo: any) => {
        return (
            <div className="p-2 overflow-auto bg-[var(--primary-color)] cursor-pointer rounded-[var(--border-radius)]">
                <ShiftItem shift={getShiftById(eventInfo.event.title)}/>
            </div>
        )
    }

    const onDateChange = (event: any) => {
        setSelectedDate(event.start);
    }

    return (
        <div className="py-2 px-6 scroll-bar-hidden">
            <OverlayPanel ref={assignShiftOverlayPanel}
                          showCloseIcon
                          onHide={onHideAssignShiftOverlayPanel}
                          className={`primary-container p-0 min-w-[18rem] min-h-[20rem] border-4
                                                ${selectedShift ? 'border-white' : 'border-yellow-500'}
                                              `}
            >
                <div
                    className="flex flex-col justify-center items-center p-4 space-y-2 w-[18rem] text-[var(--text-color)]">
                    <div className="w-full space-y-1">
                        <div>{t('weeklyCalendar.pickStartTime')}</div>
                        <Calendar className="w-full"
                                  timeOnly selectionMode="single"
                                  value={startTime}
                                  onChange={setStartHour}
                                  stepMinute={STEP_MINUTE}
                                  placeholder={t('weeklyCalendar.pickStartTime')}
                        />
                    </div>
                    <div className="w-full space-y-1">
                        <div>{t('weeklyCalendar.pickEndTime')}</div>
                        <Calendar className="w-full"
                                  timeOnly selectionMode="single"
                                  value={endTime}
                                  onChange={setEndHour}
                                  stepMinute={STEP_MINUTE}
                                  placeholder={t('weeklyCalendar.pickEndTime')}
                        />
                    </div>
                    <div className="w-full space-y-1">
                        <div>{t('weeklyCalendar.selectSupervisor')}</div>
                        <Dropdown className="w-full min-h-11"
                                  value={selectedSupervisor}
                                  options={availableSupervisors}
                                  optionLabel="id"
                                  placeholder={t('weeklyCalendar.selectSupervisor')}
                                  checkmark={true} highlightOnSelect={false}
                                  onChange={(e) => handleSupervisorSelection(e)}
                                  itemTemplate={selectedEmployeeItem}
                                  valueTemplate={selectedEmployeeItem}
                        />
                    </div>
                    <div className="w-full space-y-1">
                        <div>{t('weeklyCalendar.selectOperators')}</div>
                        <MultiSelect className="w-full min-h-11"
                                     value={selectedOperators}
                                     onChange={(e) => setSelectedOperators(e.value)}
                                     options={availableOperators} optionLabel="id"
                                     optionDisabled={(option) => option.supervisorId !== selectedSupervisor?.id}
                                     placeholder={t('weeklyCalendar.selectOperators')}
                                     itemTemplate={selectedEmployeeItem}
                                     selectedItemTemplate={selectedEmployeeItem}
                        />
                    </div>
                    <div className="w-full space-y-1">
                        <div>{t('weeklyCalendar.location')}</div>
                        <InputText value={selectedLocation} className="w-full"
                                   placeholder={t('weeklyCalendar.location')}
                                   onChange={(e) => setSelectedLocation(e.target.value)}
                        />
                    </div>
                    <div className="w-full">
                        <Button className="space-x-1 h-8" onClick={saveShift}>
                            <FaRegEdit size={20}></FaRegEdit>
                            {
                                !selectedShift
                                    ? <p>{t('weeklyCalendar.save')}</p>
                                    : <p>{t('weeklyCalendar.edit')}</p>
                            }
                        </Button>
                    </div>
                </div>
            </OverlayPanel>
            <FullCalendar
                height={680}
                plugins={[interactionPlugin, resourceTimelinePlugin, listPlugin]}
                schedulerLicenseKey={FULLCALENDAR_API_KEY}
                headerToolbar={{
                    left: 'prev,next today',
                    center: 'title',
                    right: 'resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth'
                }}
                resourceAreaHeaderContent='Shifts'
                defaultAllDay={false}
                initialView='resourceTimelineWeek'
                editable={true}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
                weekends={true}
                datesSet={onDateChange}
                resources={getDayResources()}
                events={getCalendarEvent()}
                // eventSources={getCalendarEvent(availableShifts)}
                // initialEvents={INITIAL_EVENTS} // alternatively, use the `events` setting to fetch from a feed
                select={handleDateSelect}
                eventContent={renderEventContent} // custom render function
                eventClick={handleEventClick}
                eventsSet={handleEvents} // called after events are initialized/added/changed/removed
                eventAdd={() => {
                    console.log('eventAdd');
                }}
                eventChange={(event) => {
                    handleEventChange(event);
                }}
                loading={(isLoading) => {
                    setIsLoading(isLoading);
                }}
                /* you can update a remote database when these fire:
                eventAdd={function(){}}
                eventChange={function(){}}
                eventRemove={function(){}}
                */
            />

        </div>
    );
};

export default WeeklyCalendar;