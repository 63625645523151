import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import CreateCommunication from "../../components/communication/CreateCommunication";
import CommunicationArchive from "../../components/communication/CommunicationArchive";

const CommunicationPage: React.FC = () => {
    const {t} = useTranslation();
    const [refresh, setRefresh] = useState<boolean>(false);

    const handleSend = () => {
        setInterval(() => {
            setRefresh(!refresh);
        }, 1000);
    };

    return (
        <>
            <div className="page-container">
                <div className="page-title">
                    <h2>{t('communicationPage.title')}</h2>
                </div>
                <div className="page-content space-y-20">
                    <CreateCommunication onCommunicationSent={handleSend}/>
                    <CommunicationArchive refresh={refresh}/>
                </div>
            </div>
        </>
    );
};

export default CommunicationPage;
