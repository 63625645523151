import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Bar, BarChart, CartesianGrid, Rectangle, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import Loader from "../common/LoaderComponent";
import {useTranslation} from 'react-i18next';
import {FiDownload} from "react-icons/fi";
import {BlobProvider} from "@react-pdf/renderer";
import IncidentsSummary from "../pdf/summaries/IncidentsSummary";
import {incidentsGraph} from "../../services/backend/api/graphs/graphs.apis";
import FiltersSelector from "./FiltersSelector";
import {retrieveIncidentsTypologies} from "../../services/data-manager/data-manager.service";
import {IncidentTypology} from "../../models/incident-typology.interface";
import {CircleArea} from '../../models/geo.interface';
import {IncidentsGraphRequest} from "../../services/backend/api/graphs/requests/incidents-graph.request";

interface IncidentsGraphProps {
    compact?: boolean;
    incidentGraphData?: any;
}

const IncidentsGraph: React.FC<IncidentsGraphProps> = ({compact, incidentGraphData}) => {
    const {t, i18n} = useTranslation();
    const [activeTab, setActiveTab] = useState(t("provider_graph.Today"));

    const [isLoading, setIsLoading] = useState(false);
    const [graphData, setGraphData] = useState<any>([]);
    const [maxValue, setMaxValue] = useState(50);

    const [containerDimensions, setContainerDimensions] = useState({width: 0, height: 0});
    const containerRef = useRef<HTMLDivElement>(null);
    const formatDate = (date: Date): string => {
        return date.toISOString().split('T')[0];
    };

    const [startDate, setStartDate] = useState<Date>(new Date());
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [availableTypologies, setAvailableTypologies] = useState<IncidentTypology[]>([]);
    const [selectedSeverities, setSelectedSeverities] = useState<string[]>([]);
    const [selectedTypologies, setSelectedTypologies] = useState<IncidentTypology[]>([]);
    const [selectedLocation, setSelectedLocation] = useState<CircleArea | null>(null);

    const handleTypologiesChange = (values: string[]) => {
        const selected = availableTypologies.filter((typology) => values.includes(typology.incidentName));
        setSelectedTypologies(selected);
    }

    const handleSeveritiesChange = (values: string[]) => {
        setSelectedSeverities(values.map((severity) => {
            switch (severity) {
                case t("filters.severities.minor"):
                    return "0";
                case t("filters.severities.medium"):
                    return "1";
                case t("filters.severities.high"):
                    return "2";
                default:
                    return "0";
            }
        }));
    }

    const setDateRangeFromString = (tab: string) => {
        const today = new Date();
        setActiveTab(tab);
        switch (tab) {
            case t("provider_graph.Today"):
                setStartDate(today);
                setEndDate(today);
                break;
            case t("provider_graph.ThisWeek"):
                const startWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());
                setStartDate(startWeek);
                setEndDate(today);
                break;
            case t("provider_graph.ThisMonth"):
                const startMonth = new Date(today.getFullYear(), today.getMonth(), 1);
                setStartDate(startMonth);
                setEndDate(today);
                break;
            case t("provider_graph.ThisYear"):
                const startYear = new Date(today.getFullYear(), 0, 1);
                setStartDate(startYear);
                setEndDate(today);
                break;
            default:
                setStartDate(today);
                setEndDate(today);
                break;
        }
    };

    const fetchGraph = useCallback(async () => {
        if (!startDate || !endDate) {
            return;
        }
        try {
            setIsLoading(true);
            let graphData = incidentGraphData;
            if (!graphData) {
                console.log("Fetching incident graph data from ", startDate, " to ", endDate);
                let incidents: string[] | undefined = selectedTypologies.map((typology) => typology.incidentId);
                if (incidents.length === 0) {
                    incidents = undefined;
                }
                let severities: number[] | undefined = selectedSeverities.map((severity) => parseInt(severity));
                if (severities.length === 0) {
                    severities = undefined;
                }
                const request: IncidentsGraphRequest = {
                    startDate,
                    endDate,
                    position: selectedLocation || undefined,
                    severity: severities,
                    incidentIds: incidents,
                }
                graphData = await incidentsGraph(request);
            }

            let maxValue = 0
            const formattedData = Object.keys(graphData.summary).map((day) => {
                const {G, Y, R} = graphData.summary[day];
                const max = Math.max(maxValue, G, Y, R);
                maxValue = max > maxValue ? max : maxValue;
                return {day: day.slice(5), MINOR: G, MEDIUM: Y, MAJOR: R}
            });

            setMaxValue(maxValue);
            setGraphData(formattedData);
            setIsLoading(false);
        } catch (error) {
            console.error("Errore durante il recupero dei dati delle goal:", error);
            setIsLoading(false);
        }
    }, [endDate, incidentGraphData, selectedLocation, selectedSeverities, selectedTypologies, startDate]);

    useEffect(() => {
        retrieveIncidentsTypologies().then((typologies) => {
            setAvailableTypologies(typologies);
        });
    }, []);

    useEffect(() => {
        fetchGraph().then(() => console.log('fetched'));
    }, [endDate, fetchGraph, startDate]);

    useEffect(() => {
        const updateDimensions = () => {
            if (containerRef.current) {
                setContainerDimensions({
                    width: containerRef.current.offsetWidth,
                    height: containerRef.current.offsetHeight
                });
            }
        };

        updateDimensions();
        window.addEventListener('resize', updateDimensions);

        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    return (
        <div className="card-container"
             style={{
                 padding: compact ? "0" : "0.6rem"
             }}
        >
            {
                !compact &&
                <h2 className="card-title">{t("provider_graph.Incident")}</h2>
            }

            <FiltersSelector
                showDate={true}
                showSeverity={true}
                showTypology={true}
                showGps={true}
                onDateChange={(period) => setDateRangeFromString(period)}
                onSeveritiesChange={(values) => handleSeveritiesChange(values)}
                onTypologiesChange={(values) => handleTypologiesChange(values)}
                typologies={availableTypologies.map((typology) => typology.incidentName)}
                onGpsChange={(location: CircleArea | null) => {
                    console.log("Debugging GPS: ", location);
                    setSelectedLocation(location)}
                }
            />
            <div className="card-content">
                <div ref={containerRef} className="graph"
                     style={{
                         height: compact ? "10.8rem" : "16rem",
                     }}
                >
                    {
                        isLoading && <div className='w-full h-full flex justify-center items-center'>
                            <Loader/>
                        </div>
                    }
                    {!isLoading && containerDimensions.width > 0 && containerDimensions.height > 0 && (
                        <ResponsiveContainer width="100%" height="100%">
                            <BarChart
                                data={graphData}
                                layout="vertical"
                                margin={{top: 8, right: 38, left: 32, bottom: 2}}
                                barSize={8}
                            >
                                <CartesianGrid strokeDasharray="3 3" horizontal={false}/>
                                <XAxis type="number" domain={[0, maxValue]} stroke="#fff"/>
                                <YAxis dataKey="day" type="category" axisLine={false} tickLine={false} stroke="#fff"/>
                                <Tooltip
                                    contentStyle={{backgroundColor: '#333', border: 'none'}}
                                    itemStyle={{color: '#fff'}}
                                />
                                <Bar dataKey="MINOR" fill="#4CAF50" activeBar={<Rectangle fill="#b7e0b8"/>}/>
                                <Bar dataKey="MEDIUM" fill="#FFC107" activeBar={<Rectangle fill="#ffe69c"/>}/>
                                <Bar dataKey="MAJOR" fill="#F44336" activeBar={<Rectangle fill="#fbb4af"/>}/>
                            </BarChart>
                        </ResponsiveContainer>
                    )}

                </div>
            </div>

            <BlobProvider document={<IncidentsSummary data={graphData}/>}>
                {({url, blob}) => (
                    url &&
                    <a href={url} target="_blank" className="primary-button uppercase bordered-button "
                       rel="noreferrer">
                        <FiDownload size={20} className="mx-2"/>
                        {t("documentPage.download")}
                    </a>
                )}
            </BlobProvider>
        </div>
    );
};

export default IncidentsGraph;