import React, {useEffect, useState} from "react";
import {CalendarRequest, formatDayAndMonthDate, formatTime} from "../utils/date.utils";
import {useTranslation} from "react-i18next";
import {getMonthShifts} from "../services/backend/api/shifts/shifts.apis";
import {MonthShiftsRequest} from "../services/backend/api/shifts/requests/month-shifts.request";


interface InfoDayProps {
    selectedDay: Date | null;
}

const InfoDay: React.FC<InfoDayProps> = ({selectedDay}) => {

  const { t, i18n } = useTranslation();
  const locale = i18n.language === "ar" ? "ar-SA" : "en-US";
  const [shifts, setShifts] = useState<CalendarRequest[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const month = selectedDay?.getMonth() || new Date().getMonth();
      const year = selectedDay?.getFullYear() || new Date().getFullYear();
        const request: MonthShiftsRequest = {month, year};
        const responseMonth = await getMonthShifts(request);
      if (responseMonth) {
        const newShifts: CalendarRequest[] = [];
        responseMonth.monthCalendar.forEach((day: any) => {
          day.shifts.forEach((shift: any) => {
            const {label, startTime, endTime, location} = shift;
            newShifts.push({label, startTime, endTime, location});
          });
        });
        setShifts(newShifts); // Aggiorna l'array shifts utilizzando setShifts
      }
    };
  
    fetchData();
  }, [selectedDay]);
  

  const filteredShifts = shifts.filter(shift => {
    const shiftDate = new Date(shift.startTime);
    return shiftDate.getDate() === selectedDay?.getDate() &&
      shiftDate.getMonth() === selectedDay?.getMonth() &&
      shiftDate.getFullYear() === selectedDay?.getFullYear();
  });
  

  
  return (
    <div style={{ minHeight: "50px" }} className="mt-0 mb-4">

    {filteredShifts.map((shift, index) => (
      <div key={index}>

        <p className="text-xs">{t(shift.label)}: {formatDayAndMonthDate(shift.startTime) !== formatDayAndMonthDate(shift.endTime) ?
            <span
                style={{fontSize: '10px'}}>{formatDayAndMonthDate(shift.startTime, locale)} {formatTime(shift.startTime, locale)} - {formatDayAndMonthDate(shift.endTime)} {formatTime(shift.endTime)}</span>
         :
         <span style={{fontSize:'10px'}}>{formatTime(shift.startTime, locale)} - {formatTime(shift.endTime, locale)}</span> 
        }</p>

        <p className="text-xs">
          {t("location")}: {shift?.location || t("unspecified")}
        </p>
       
      </div>
    ))}
  </div>
  );
}
export default InfoDay;
