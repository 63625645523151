import {consumeApi, GET, INCIDENTS, POST, SHIFTS} from "../../backend.service";
import {ProposeShiftRequest} from "./requests/propose-shift.request";
import {ProposeShiftResponse} from "./responses/propose-shift.response";
import {MonthShiftsRequest} from "./requests/month-shifts.request";
import {MonthShiftsResponse} from "./responses/month-shifts.response";
import {WeekShiftsResponse} from "./responses/week-shifts.response";
import {PendingRequestsRequest} from "./requests/pending-requests.request";
import {PendingRequestsResponse} from "./responses/pending-requests.response";
import {formatDate} from "../../../../utils/date.utils";
import {EmployeeWorkReportsRequest} from "./requests/employee-work-reports.request";
import {EmployeeWorkReportsResponse} from "./responses/employee-work-reports.response";
import {IncidentNotificationReportsRequest} from "./requests/incident-notification-reports.request";
import {EndShiftReportsRequest} from "./requests/end-shift-reports.request";
import {IncidentNotificationReportsResponse} from "./responses/incident-notification-reports.response";
import {ShiftsWeekByDateRequest} from "./requests/shifts-week-by-date.request";
import {ShiftsWeekByDateResponse} from "./responses/shifts-week-by-date.response";
import {WorkersByDateRequest} from "./requests/workers-by-date.request";
import {WorkersByDateResponse} from "./responses/workers-by-date.response";
import {AvailableWorkersBetweenDatesRequest} from "./requests/available-workers-between-dates.request";
import {AvailableWorkersBetweenDatesResponse} from "./responses/available-workers-between-dates.response";
import {ShiftsWeekStatsRequest} from "./requests/shifts-week-stats.request";
import {ShiftsWeekStatsResponse} from "./responses/shifts-week-stats.response";
import {ShiftsBetweenDatesRequest} from "./requests/shifts-between-dates.request";
import {ShiftsBetweenDatesResponse} from "./responses/shifts-between-dates.response";
import {UpdateCalendarResponse} from "./responses/update-calendar.response";
import {UpdateCalendarRequest} from "./requests/update-calendar.request";
import {ValidateShiftRequest} from "./requests/validate-shift.request";
import {ValidateShiftResponse} from "./responses/validate-shift.response";
import {UserLeavesRequest} from "./requests/user-leaves.request";
import {UserLeavesResponse} from "./responses/user-leaves.response";
import {UploadLeaveDocumentRequest} from "./requests/upload-leave-document.request";
import {UploadLeaveDocumentResponse} from "./responses/upload-leave-document.response";
import {TimesheetRequest} from "./requests/timesheet.request";
import {TimesheetResponse} from "./responses/timesheet.response";

export const getCalendarLabels = async (): Promise<any> => {
    return await consumeApi(GET, `${SHIFTS}/labels`, "Get Calendar Labels", {})
}

export const proposeShift = async (request: ProposeShiftRequest): Promise<ProposeShiftResponse> => {
    return await consumeApi(POST, `${SHIFTS}/propose`, "Calendar Propose", request, true)
};

export const validateShift = async (request: ValidateShiftRequest): Promise<ValidateShiftResponse> => {
    return await consumeApi(POST, `${SHIFTS}/propose/validate`, "Validate Propose", request, true)
};

export const getMonthShifts = async (request: MonthShiftsRequest): Promise<MonthShiftsResponse> => {
    return await consumeApi(POST, `${SHIFTS}/month`, "Get Calendar Month", request, true)
};

export const getWeekShifts = async (): Promise<WeekShiftsResponse> => {
    return await consumeApi(GET, `${SHIFTS}/week`, "Fetch Week", {}, true);
};

export const getUserLeaves = async (request: UserLeavesRequest): Promise<UserLeavesResponse> => {
    return await consumeApi(POST, `${SHIFTS}/hr/leaves/get-by-operator`, "User Leaves", {
        ...request,
        startDate: formatDate(request.startDate),
        endDate: formatDate(request.endDate)
    }, true);
};

export const getPendingRequests = async (request: PendingRequestsRequest): Promise<PendingRequestsResponse> => {
    return await consumeApi(POST, `${SHIFTS}/pending_request/by-operator`, "Pending Requests", request, true);
};

export const getEmployeeWorkReports = async (request: EmployeeWorkReportsRequest): Promise<EmployeeWorkReportsResponse> => {
    return await consumeApi(POST, `${SHIFTS}/hr/get-by-operator`, "HR Employee Work Report", {
        ...request,
        startDate: formatDate(request.startDate),
        endDate: formatDate(request.endDate)
    }, true);
};

export const getIncidentsNotificationReports = async (request: IncidentNotificationReportsRequest): Promise<IncidentNotificationReportsResponse> => {
    return await consumeApi(POST, `${INCIDENTS}/get/notifications-by-operator/between-dates`, "HR Employee Work Report", {
        ...request,
        startDate: formatDate(request.startDate),
        endDate: formatDate(request.endDate)
    }, true);
};

export const getEndShiftReports = async (request: EndShiftReportsRequest): Promise<EmployeeWorkReportsResponse> => {
    return await consumeApi(POST, `${SHIFTS}/hr/get-by-operator`, "HR Employee Work Report", {
        ...request,
        startDate: formatDate(request.startDate),
        endDate: formatDate(request.endDate)
    }, true);
};


export const getShiftsBetweenDates = async (request: ShiftsBetweenDatesRequest): Promise<ShiftsBetweenDatesResponse> => {
    return await consumeApi(POST, `${SHIFTS}/hr/get-by-dates`, "HR Shifts Between Dates", {
        ...request,
        startDate: formatDate(request.startDate),
        endDate: formatDate(request.endDate)
    }, true);
};

export const getShiftsWeekStats = async (request: ShiftsWeekStatsRequest): Promise<ShiftsWeekStatsResponse> => {
    return await consumeApi(POST, `${SHIFTS}/working/hours/stats`, "Shifts Week Stats", request, true)
}

export const getShiftsWeekByDate = async (request: ShiftsWeekByDateRequest): Promise<ShiftsWeekByDateResponse> => {
    return await consumeApi(POST, `${SHIFTS}/week/calendar`, "Week Shifts By Date", {
        ...request,
        date: formatDate(request.date)
    }, true)
}

export const getWorkersByDate = async (request: WorkersByDateRequest): Promise<WorkersByDateResponse> => {
    return await consumeApi(POST, `${SHIFTS}/worker/day`, "Workers By Date", {
        ...request,
        dateDate: formatDate(request.dateDate)
    }, true)
}

export const getAvailableWorkersBetweenDates = async (request: AvailableWorkersBetweenDatesRequest): Promise<AvailableWorkersBetweenDatesResponse> => {
    return await consumeApi(POST, `${SHIFTS}/avaible/worker/interval`, "Available Workers Between Dates", request, true)
}

export const updateCalendar = async (request: UpdateCalendarRequest): Promise<UpdateCalendarResponse> => {
    return await consumeApi(POST, `${SHIFTS}/calendar/assign`, "Update Calendar", request, true)
}

export const uploadLeaveDocument = async (request: UploadLeaveDocumentRequest): Promise<UploadLeaveDocumentResponse> => {
    return await consumeApi(POST, `${SHIFTS}/hr/add-document-to-leave`, "Upload Leave Document", request, true)
}

export const getTimesheet = async (request: TimesheetRequest): Promise<TimesheetResponse> => {
    return await consumeApi(POST, `${SHIFTS}/worker/timesheet`, "Timesheet", {
        ...request,
        startDate: formatDate(request.startDate),
        endDate: formatDate(request.endDate)
    }, true)
}