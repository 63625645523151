import React, {createRef, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {ALL} from "../../utils/constants.utils";
import {FaAngleDown} from "react-icons/fa6";
import {UserRole} from "../../models/enums/user-role.enum";
import {
    sendClientCommunication,
    sendCommunication
} from "../../services/backend/api/communications/communications.apis";
import {Receiver} from "../../models/receiver.interface";
import {retrieveGroupMembers} from "../../services/data-manager/data-manager.service";
import {CommunicationReceiver} from "../../models/enums/communication-receiver.enum";
import {useUserContext} from "../../contexts/OperatorContextProvider";
import {SendCommunicationRequest} from "../../services/backend/api/communications/requests/send-communication.request";
import {useToastContext} from "../../contexts/ToastContext";
import {OverlayPanel} from "primereact/overlaypanel";
import {Button} from "primereact/button";

interface CreateCommunicationProps {
    onCommunicationSent?: () => void
}

const CreateCommunication: React.FC<CreateCommunicationProps> = ({onCommunicationSent}: CreateCommunicationProps) => {
    const {t} = useTranslation();
    const {level} = useUserContext();
    const {successToast, errorToast} = useToastContext();
    const confirmationOverlayPanel = useRef<OverlayPanel>(null);

    const receiversOptions: Receiver[] = useMemo<Receiver[]>(() => [
        {
            key: ALL,
            value: ALL,
            receiver: CommunicationReceiver.All,
            label: t(`communication.${ALL}`),
            selected: false,
            group: false,
            all: false,
            items: []
        },
        {
            key: UserRole.Supervisor,
            value: UserRole.Supervisor,
            receiver: CommunicationReceiver.Supervisor,
            label: t(`communication.${UserRole.Supervisor}`),
            selected: false,
            group: true,
            all: false,
            items: []
        },
        {
            key: UserRole.Operator,
            value: UserRole.Operator,
            receiver: CommunicationReceiver.Operator,
            label: t(`communication.${UserRole.Operator}`),
            selected: false,
            group: true,
            all: false,
            items: []
        },
        {
            key: UserRole.HR,
            value: UserRole.HR,
            receiver: CommunicationReceiver.HR,
            label: t(`communication.${UserRole.HR}`),
            selected: false,
            group: false,
            all: false,
            items: []
        },
        {
            key: UserRole.Provider,
            value: UserRole.Provider,
            receiver: CommunicationReceiver.Provider,
            label: t(`communication.${UserRole.Provider}`),
            selected: false,
            group: false,
            all: false,
            items: []
        },
        {
            key: UserRole.Client,
            value: UserRole.Client,
            receiver: CommunicationReceiver.Client,
            label: t(`communication.${UserRole.Client}`),
            selected: false,
            group: false,
            all: false,
            items: []
        },
    ], [t]);


    const [message, setMessage] = useState<string>('');
    const [openDropdowns, setOpenDropdowns] = useState({} as { [key: string]: any });
    const [receivers, setReceivers] = useState<Receiver[]>([]);
    const [selectedReceivers, setSelectedReceivers] = useState<string[]>([]);
    const receiversSelector = createRef<HTMLDivElement>();

    const findReceiver = (receiver: CommunicationReceiver): Receiver | undefined => {
        return receivers.find(r => r.receiver === receiver)
    }
    const findReceiverMembers = (receiver?: Receiver): boolean | string[] => {
        if (!receiver) {
            return false
        }
        if (receiver.all) {
            return true
        }
        return receiver.items.filter(item => selectedReceivers.includes(item.id)).map(item => item.id)
    }

    const submit = () => {
        const allReceiversSelected = findReceiver(CommunicationReceiver.All)?.selected || false
        const supervisorsReceivers = findReceiver(CommunicationReceiver.Supervisor)
        const operatorsReceivers = findReceiver(CommunicationReceiver.Operator)
        const hrReceivers = findReceiver(CommunicationReceiver.HR)
        const providerReceivers = findReceiver(CommunicationReceiver.Provider)
        const clientReceivers = findReceiver(CommunicationReceiver.Client)

        const selectedSupervisors = allReceiversSelected || findReceiverMembers(supervisorsReceivers)
        const selectedOperators = allReceiversSelected || findReceiverMembers(operatorsReceivers)
        const selectedHR = allReceiversSelected || hrReceivers?.selected || false
        const selectedProvider = allReceiversSelected || providerReceivers?.selected || false
        const selectedClient = allReceiversSelected || clientReceivers?.selected || false

        const communicationRequest: SendCommunicationRequest = {
            supervisors: selectedSupervisors,
            operators: selectedOperators,
            hr: selectedHR,
            provider: selectedProvider,
            client: selectedClient,
            message: message
        };
        if (notReceiverSelected(communicationRequest)) return
        if (level === UserRole.Client) {
            sendClientCommunication(communicationRequest).then(() => {
                console.log("Client Communication Sent")
                setMessage('')
                successToast(t('messages.newCommunicationSuccess'));
            }).catch(error => {
                console.error(`Error sending client communication: ${error}`);
                errorToast(t('messages.newCommunicationError'));
            })
        } else {
            sendCommunication(communicationRequest).then(() => {
                console.log("Communication Sent")
                setMessage('')
                successToast(t('messages.newCommunicationSuccess'));
            }).catch(error => {
                console.error(`Error sending communication: ${error}`);
                errorToast(t('messages.newCommunicationError'));
            })
        }
        onCommunicationSent && onCommunicationSent();
        confirmationOverlayPanel?.current?.hide();
    }

    const notReceiverSelected = (communicationRequest: SendCommunicationRequest) => {
        return !communicationRequest.supervisors && !communicationRequest.operators && !communicationRequest.hr &&
            !communicationRequest.provider && !communicationRequest.client
    }

    const handleCheckboxChange = (key: string) => {
        setReceivers(receivers.map(receiver =>
            receiver.key === key ? {...receiver, all: !receiver.all} : receiver
        ));
    };

    const handleButtonClick = (key: string) => {
        const receiver = receivers.find(receiver => receiver.key === key)
        if (!receiver || receiver.group) {
            return
        }
        setReceivers(receivers.map(receiver =>
            receiver.key === key ? {...receiver, selected: !receiver.selected} : receiver
        ));
    };

    const handleReceiverSelected = (item: string) => {
        if (selectedReceivers.includes(item)) {
            setSelectedReceivers(selectedReceivers.filter(i => i !== item))
        } else {
            setSelectedReceivers([...selectedReceivers, item])
        }
    };

    const showReceiversList = (receiver: Receiver) => {
        setOpenDropdowns(prevState => ({
            ...prevState,
            [receiver.key]: !prevState[receiver.key]
        }));
    };

    const isItemSelected = (item: string) => {
        return selectedReceivers.includes(item);
    }

    const showConfirmationModal = (e: any) => {
        if (message.length === 0) {
            return
        }
        confirmationOverlayPanel?.current?.toggle(e);
    }

    const formatPlaceholder = (placeholder: string) => {
        let formattedMembers = ""
        const allReceiversSelected = findReceiver(CommunicationReceiver.All)?.selected || false
        if (allReceiversSelected) {
            return `${placeholder} all`
        } else {
            for (const receiver of receivers) {
                if (receiver.all) {
                    formattedMembers += receiver.label + ", "
                    continue
                }
                if (receiver.selected) {
                    formattedMembers += receiver.label + ", "
                } else {
                    const receiversName = selectedReceivers.map(id => receiver.items.find(item => item.id === id)?.name)
                    if (receiversName.length > 0) {
                        for (const name of receiversName) {
                            if (name) {
                                formattedMembers += name + ", "
                            }
                        }
                    }
                }
            }
            formattedMembers = formattedMembers.substring(0, formattedMembers.length - 2)
            if (formattedMembers.length <= 0) {
                formattedMembers = "none"
            }
        }
        return `${placeholder} ${formattedMembers}`;
    }

    const handleClickOutside = useCallback((event: MouseEvent) => {
        if (receiversSelector.current && !receiversSelector.current.contains(event.target as Node)) {
            setOpenDropdowns({});
        }
    }, [receiversSelector]);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [handleClickOutside]);

    useEffect(() => {
        const availableReceivers = CommunicationReceiver.getAvailableReceivers(level)
        let options = receiversOptions.filter(option => option.receiver === CommunicationReceiver.All || availableReceivers.includes(option.receiver))
        if (options.length === 2) {
            options = options.filter(option => option.receiver !== CommunicationReceiver.All)
            options[0].selected = true
        }
        const receiversMembers = options.map(option => {
            if (option.group) {
                retrieveGroupMembers({level: option.key as UserRole}).then(members => {
                    option.items = members
                })
            }
            return option
        })
        setReceivers(receiversMembers)
    }, [level, receiversOptions]);

    return (
        <div className="card-container">
            <div className="section-title">
                <h2>{t('communication.newCommunicationTitle')}</h2>
            </div>
            <div className="flex justify-between items-center">
                <div className="flex space-x-4" ref={receiversSelector}>
                    {receivers.map((receiver) => (
                        <div key={receiver.key}
                             className={`primary-button uppercase receiver-selector-item`}
                             style={{
                                 backgroundColor: receiver.selected ? 'whitesmoke' : 'var(--primary-color)',
                                 color: receiver.selected ? 'var(--primary-color)' : 'white'
                             }}
                        >
                            {
                                receiver.group &&
                                <input
                                    className="w-6 h-6 rounded border-2 border-white"
                                    type="checkbox"
                                    id={receiver.key}
                                    name={receiver.key}
                                    value={receiver.value}
                                    checked={receiver.all}
                                    onChange={() => handleCheckboxChange(receiver.key)}
                                />
                            }
                            <button onClick={() => handleButtonClick(receiver.key)}>
                                {receiver.label}
                            </button>
                            {
                                receiver.group &&
                                <>
                                    <FaAngleDown size={16} onClick={() => showReceiversList(receiver)}/>
                                    {openDropdowns[receiver.key] && (
                                        <div className="dropdown-content translate-y-32 -translate-x-6">
                                            {receiver.items && receiver.items.map((item, index) => (
                                                <div key={index}
                                                     className={`dropdown-item ${isItemSelected(item.id) ? 'bg-[var(--primary-color-300)] text-color-black' : ''}`}
                                                     onClick={() => {
                                                         handleReceiverSelected(item.id)
                                                         showReceiversList(receiver)
                                                     }}>
                                                    {(isItemSelected(item.id) || receiver.all) &&
                                                        <span className="mr-2">✓</span>}
                                                    {item.name}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </>
                            }
                        </div>
                    ))}
                </div>
            </div>
            <div className="card-content flex flex-col justify-center items-center space-y-8 mt-4">
                <textarea className="w-full rounded-[var(--border-radius)] py-2 px-4 text-secondary"
                          name="communicationContent"
                          placeholder={formatPlaceholder(t('communication.placeholder'))}
                          rows={6} value={message}
                          onChange={(event) => setMessage(event.target.value)}
                />
                <button
                    onClick={showConfirmationModal}
                    className="primary-button uppercase bordered-button"
                >
                    {t("communication.submit")}
                </button>
            </div>
            <OverlayPanel ref={confirmationOverlayPanel}
                          className="primary-container px-8 py-2 border-2">
                <div
                    className="flex justify-center flex-col p-4 text-[color:var(--text-color)] text-center">
                    <p className="uppercase font-bold">{t("communication.confirmation.title")}</p>
                    <p className="p-2">
                        {t("communication.confirmation.subtitle")}:
                    </p>
                    <p className="p-2">
                        {message}
                    </p>
                    <p className="p-2">
                        to {formatPlaceholder("")}.
                    </p>
                    <p className="p-2">{t("communication.confirmation.message")}</p>
                    <div>
                        <div className='w-full flex items-center space-x-4 '>
                            <div className="w-44">
                                <Button className="h-10 w-44" onClick={() => confirmationOverlayPanel?.current?.hide()}
                                        style={{borderColor: "darkred"}}
                                >
                                    {t('communication.confirmation.decline')}
                                </Button>
                            </div>
                            <div className="w-44">
                                <Button className="h-10" onClick={submit}
                                        style={{borderColor: "darkgreen"}}
                                >
                                    {t('communication.confirmation.accept')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </OverlayPanel>
        </div>
    );
}
export default CreateCommunication;
