import React from 'react'
import {WorkReport} from "../../../models/work-reports.interface";
import SummaryDocument from "../components/SummaryDocument";

interface EndShiftReportsSummaryProps {
    reports: WorkReport[];
}

const EndShiftReportsSummary: React.FC<EndShiftReportsSummaryProps> = ({reports}) => {

    console.log(reports)
    return (
        <SummaryDocument title="End Shift Reports Summary" subtitle="End Shift Reports" documents={reports}
                         printableKeys={["name", "date", "startDate", "endDate", "userStart", "userEnd"]}
        />
    )
}
export default EndShiftReportsSummary