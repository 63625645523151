import React from 'react'
import GraphSummaryDocument from "../components/GraphSummaryDocument";
import {Claim} from "../../../models/claim.interface";
import {formatDate} from "../../../utils/date.utils";
import {shortId} from "../../../utils/text-formatting.utils";

interface ClaimsHistorySummaryProps {
    claims: Claim[];
}

const ClaimsHistorySummary: React.FC<ClaimsHistorySummaryProps> = ({claims}) => {
    const data = claims.map(claim => {
        return {
            id: shortId(claim.id),
            employee: claim.employee.name + " " + claim.employee.surname,
            type: claim.type,
            date: formatDate(new Date(claim.date)),
            isManaged: claim.isManaged,
            managedAt: formatDate(new Date(claim.managedAt)),
        }
    })

    return (
        <GraphSummaryDocument title="Claims History Summary" subtitle="Claims History Data" data={data}/>
    )
}
export default ClaimsHistorySummary