import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
import {useUserContext} from "../../contexts/OperatorContextProvider";
import {IoFolderOpenSharp} from "react-icons/io5";
import {HiOutlineDotsVertical} from "react-icons/hi";
import {retrieveCommunicationArchive} from "../../services/data-manager/data-manager.service";
import {CommunicationReceiver} from "../../models/enums/communication-receiver.enum";
import {OverlayPanel} from "primereact/overlaypanel";
import {
    GetCommunicationArchiveRequest
} from "../../services/backend/api/communications/requests/get-communication-archive.request";
import {oneYearAgo, today} from "../../utils/date.utils";
import JSZip from "jszip";
import {generateCommunicationDocument} from "../../utils/pdf-document.utils";
import {UserRole} from "../../models/enums/user-role.enum";

interface CommunicationArchiveProps {
    refresh: boolean
}

const CommunicationArchive: React.FC<CommunicationArchiveProps> = ({refresh}: CommunicationArchiveProps) => {
    const {t} = useTranslation();
    const {level} = useUserContext()
    const [receivers, setReceiver] = useState<CommunicationReceiver[]>([]);
    const [selectedMenuReceiver, setSelectedMenuReceiver] = useState<CommunicationReceiver>();
    const receiverMenuOverlayPanel = useRef<OverlayPanel>(null)

    type FilesCounterType = { [key in CommunicationReceiver]: number };
    const [filesCounter, setFilesCounter] = useState<FilesCounterType>();

    const handleReceiverMenuClick = (e: React.MouseEvent<SVGElement>, receiver: CommunicationReceiver) => {
        receiverMenuOverlayPanel.current?.toggle(e)
        e.preventDefault();
        e.stopPropagation();
        setSelectedMenuReceiver(receiver);
    }

    const hideReceiverMenuOverlayPanel = () => {
        receiverMenuOverlayPanel.current?.hide();
        setSelectedMenuReceiver(undefined);
    }

    const downloadAllSelectedReceiverFiles = () => {
        console.log("Download all files for receiver:", selectedMenuReceiver);

        const receiverLevel = CommunicationReceiver.getRole(selectedMenuReceiver as CommunicationReceiver) as UserRole
        if (receiverLevel === UserRole.None) {
            return
        }
        const request: GetCommunicationArchiveRequest = {
            startDate: oneYearAgo(),
            endDate: today(),
            level: receiverLevel,
        }
        retrieveCommunicationArchive(request, level).then(async (communications) => {
            communications.sort((a, b) => b.date.getTime() - a.date.getTime())
            const zip = new JSZip();

            for (let i = 0; i < communications.length; i++) {
                const communication = communications[i];
                const blob = await generateCommunicationDocument(communication);
                if (!blob) {
                    console.error("Error downloading file:", communication.id);
                    continue;
                }
                zip.file(`${communication.id}.pdf`, blob);
            }

            const content = await zip.generateAsync({type: "blob"});
            const url = window.URL.createObjectURL(content);
            const a = document.createElement("a");
            a.href = url;
            a.download = `${selectedMenuReceiver}.zip`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        })

        hideReceiverMenuOverlayPanel();
    }

    useEffect(() => {
        setReceiver(CommunicationReceiver.getAvailableReceivers(level));
    }, [level]);

    useEffect(() => {
        const categoriesFilesCounter: FilesCounterType = {} as FilesCounterType;
        for (const receiver of receivers) {
            const category = CommunicationReceiver.getRole(receiver);
            // TODO: Uncomment when the API is ready
            // retrieveCommunicationsCounterByCategory({category: category})
            //     .then((count) => {
            //         categoriesFilesCounter[receiver] = count;
            //     })
            //     .catch((error) => {
            //         console.error("Error fetching data:", error);
            //         categoriesFilesCounter[receiver] = 0;
            //     })
            //     .finally(() => {
            //         setFilesCounter({...categoriesFilesCounter});
            //     });

            // TODO: Remove the following code when the API is ready
            const request: GetCommunicationArchiveRequest = {
                startDate: oneYearAgo(),
                endDate: today(),
                level: category,
            }
            retrieveCommunicationArchive(request, level).then(communications => {
                categoriesFilesCounter[receiver] = communications.length;
            })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                    categoriesFilesCounter[receiver] = 0;
                })
                .finally(() => {
                    setFilesCounter({...categoriesFilesCounter});
                });
        }
    }, [level, receivers, refresh]);

    return (
        <div className="card-container ">
            <div className="section-title">
                <h2>{t('communication.archiveTitle')}</h2>
            </div>

            <div className="card-content flex justify-center items-center space-x-8">
                {
                    receivers.map((receiver, index) => (
                        <div className="" key={index}>
                            <NavLink to={receiver}>
                                <div
                                    className="custom-button padding-section-document style-section-document bordered-button-accent"
                                    style={{
                                        textTransform: "unset",
                                    }}
                                >
                                    <div className="mb-3 flex justify-between">
                                        <IoFolderOpenSharp
                                            size={32} color="whitesmoke"
                                        />
                                        <div className="relative">
                                            <HiOutlineDotsVertical
                                                onClick={(e) => {
                                                    handleReceiverMenuClick(e, receiver)
                                                }}
                                                size={24} color="whitesmoke"
                                            />
                                        </div>
                                    </div>
                                    <p className="title-style text-lg">
                                        {t(`communication.${CommunicationReceiver.getRole(receiver)}`)}
                                    </p>

                                    <p className="title-style text-lg">
                                        {filesCounter ? filesCounter[receiver] : t('communication.calculating')} {t('communication.files')}
                                    </p>
                                </div>
                            </NavLink>
                        </div>
                    ))
                }
                {
                    <OverlayPanel ref={receiverMenuOverlayPanel} showCloseIcon
                                  className='primary-container border border-solid border-yellow-500 min-w-40 p-2
                              translate-y-[45rem] -translate-x-36'>
                        <div className="flex flex-col cursor-pointer text-[color:var(--text-color)]">
                            <div onClick={downloadAllSelectedReceiverFiles}>
                                {t('communication.downloadAll')}
                            </div>
                            <div onClick={hideReceiverMenuOverlayPanel}>
                                {t('communication.close')}
                            </div>
                        </div>
                    </OverlayPanel>
                }
            </div>
        </div>
    );
}
export default CommunicationArchive;
