import axios, {Method} from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
import i18n from "i18next";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const LANG = process.env.REACT_APP_LANG;

const GENERIC_ERROR_MESSAGE = "Something went wrong:";

export const GET = "GET";
export const POST = "POST";
export const PUT = "PUT";

export const USERS = "/users";
export const SHIFTS = "/shifts";
export const CLAIMS = "/claims";
export const INCIDENTS = "/incidents";
export const TASKS = "/tasks";
export const COMMUNICATIONS = "/notifications";
export const NOTIFICATIONS = "/notifications";

export const USERS_DOCS = `${USERS}/docs`;
export const USERS_DOCUMENT = `${USERS}/document`;
export const USERS_CREDENTIAL = `${USERS}/credential`;
export const USERS_SUPERVISOR = `${USERS}/supervisor`;

let locale: string = LANG || "en";

i18n.on('languageChanged', language => {
    locale = language;
    console.log(`Language changed to ${language}`);
})

axios.interceptors.request.use(function (config) {
    config.headers['Accept-Language'] = locale;
    return config;
});

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const status = error.response?.status || 500;
        console.log(`Error (status code ${status}): ${error}`);
        return Promise.reject(error);
    });

async function getSession(): Promise<string> {
    const session = await AsyncStorage.getItem("session");
    if (session === null) {
        throw new Error("Session not found");
    }
    return session;
}

export const consumeApi = async (method: Method, endpoint: string, scope: string, data: any,
                                 authenticated: boolean = false, params: object = {}): Promise<any> => {
    try {
        let url: string;
        if (endpoint.startsWith("http")) {
            url = endpoint;
        } else {
            url = `${BASE_URL}${endpoint}`
        }
        const headers: any = {}
        if (authenticated) {
            const session = await getSession();
            headers["Authorization"] = `Bearer ${session}`;
        }
        const response = await axios({method, url, params, data, headers});
        return response.data;
    } catch (error: any) {
        if (error.response && error.response.data && error.response.data.message) {
            const errorMessage = error.response.data.message;
            throw new Error(errorMessage);
        } else {
            const errorMessage = `${GENERIC_ERROR_MESSAGE} | ${scope} | ${error}`;
            console.error(errorMessage);
            throw new Error(errorMessage);
        }
    }
}
